import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const GENERATE_DISTRIBUTION_STATEMENTS_MUTATION = gql(`
  mutation generateDistributionStatements($dealExitId: ID!) {
    generateDistributionStatements(dealExitId: $dealExitId) {
      distributions {
        id
        distributionDocument
      }
      fundDistributions {
        id
        distributionDocument
      }
    }
  }
`);

const GenerateDistributionStatementsConfirmButton: FC<{
  dealExitId: string;
}> = ({ dealExitId }) => {
  const [generateDistributionStatements, { loading, error }] = useMutation(
    GENERATE_DISTRIBUTION_STATEMENTS_MUTATION,
    {
      variables: {
        dealExitId,
      },
    }
  );

  return (
    <>
      <ConfirmButton
        onConfirm={generateDistributionStatements}
        loading={loading}
        error={error}
        variant="secondary"
        title="Generate Distribution Statements PDFs"
        description="This will generate (or regenerate) the distribution statement PDFs. Please ensure the values are all correct before proceeding with this step."
      >
        Generate Distribution Statements PDFs
      </ConfirmButton>
    </>
  );
};

export default GenerateDistributionStatementsConfirmButton;
