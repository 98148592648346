import { useState } from 'react';

const useScroll = ({ pageIdentifier }: { pageIdentifier: string }) => {
  const [scrollPosition, setScrollPosition] = useState<string | null>(
    sessionStorage.getItem(pageIdentifier)
  );
  const [hasScrolled, setHasScrolled] = useState(false);

  const onDataLoad = () => {
    if (!scrollPosition || hasScrolled) return;

    window.scrollTo(0, parseFloat(scrollPosition));
    setHasScrolled(true);
  };

  const onComponentUnmount = () => {
    sessionStorage.setItem(pageIdentifier, scrollPosition ? scrollPosition.toString() : '0');
  };

  const onScroll = (scrollPosition: number) => {
    setScrollPosition(scrollPosition.toString());
  };

  const onReset = () => {
    setScrollPosition('0');
    setHasScrolled(false);
  };

  return { onScroll, onComponentUnmount, onDataLoad, onReset };
};

export default useScroll;
