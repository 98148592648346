import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_DISTRIBUTIONS_MUTATION = gql(`
  mutation removeDistributions($dealExitId: ID!) {
    removeDistributions(dealExitId: $dealExitId) {
      id
    }
  }
`);

interface RemoveDistributionsConfirmButtonProps {
  dealExitId: string;
  className?: string;
}

const RemoveDistributionsConfirmButton: FC<RemoveDistributionsConfirmButtonProps> = ({
  dealExitId,
  className,
}) => {
  const [removeDistributions, { loading, error }] = useMutation(REMOVE_DISTRIBUTIONS_MUTATION, {
    update(cache, { data }) {
      // Update the cache to remove the distributions
      cache.modify({
        id: cache.identify({ __typename: 'DealExitType', id: dealExitId }),
        fields: {
          distributions() {
            return { nodes: [] };
          },
          fundDistributions() {
            return { nodes: [] };
          },
        },
      });
    },
  });

  const handleConfirm = async () => {
    await removeDistributions({
      variables: {
        dealExitId,
      },
    });
  };

  return (
    <ConfirmButton
      onConfirm={handleConfirm}
      loading={loading}
      error={error}
      title="Remove Distributions Data"
      description="Are you sure you want to remove all distributions data? This action cannot be undone."
      confirmButtonLabel="Remove Distributions Data"
      variant="secondary"
      className={className}
    >
      Remove Distributions Data
    </ConfirmButton>
  );
};

export default RemoveDistributionsConfirmButton;
