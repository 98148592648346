import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import dealExitTypeEnumToReadable from 'utils/enums/deal-exit-type-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const DEAL_EXITS_QUERY = gql(`
  query fetchDealExits($cursor: ID, $limit: Int, $filters: DealExitsFilterType) {
    dealExits(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        type
        percentageOfShares
        status
        deal {
          id
          schemeName
          companyInformation {
            id
            company {
              id
              name
            }
          }
        }
        exitedAt
        multipleOnInvestment
        statistics {
          numberOfDistributions
          numberOfOldPlatformDistributions
        }
      }
      pageInfo {
        hasNextPage
        cursor
      }
      statistics {
        dealExitStatisticsBreakdownByType {
          type
          numberOfDealExits
        }
        dealExitStatisticsBreakdownByStatus {
          status
          numberOfDealExits
        }
      }
    }
  }
`);

const DealExitsPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, networkStatus, variables } = useQuery(
    DEAL_EXITS_QUERY,
    {
      variables: {
        limit: 50,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const dealExits = data.dealExits.nodes;
    const pageInfo = data.dealExits.pageInfo;
    const statistics = data.dealExits.statistics;

    const filters = [
      {
        type: 'status',
        label: 'Status',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.dealExitStatisticsBreakdownByStatus.map(
            ({ status, numberOfDealExits }) => ({
              label: `${statusEnumToReadable(status).label} (${numberOfDealExits})`,
              value: status,
            })
          ),
        ],
        readable: statusEnumToReadable,
      },
      {
        type: 'type',
        label: 'Type',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.dealExitStatisticsBreakdownByType.map(({ type, numberOfDealExits }) => ({
            label: `${dealExitTypeEnumToReadable(type).label} (${numberOfDealExits})`,
            value: type,
          })),
        ],
      },
    ];

    return (
      <>
        <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
        <DataTable
          data={dealExits}
          onClick={dealExit => navigate(`/deal-exits/${dealExit.id}`)}
          columns={[
            {
              label: '#',
              fieldName: 'index',
              type: 'INDEX',
            },
            {
              label: 'Scheme',
              fieldName: 'deal.schemeName',
            },
            {
              label: 'Company',
              fieldName: 'deal.companyInformation.company.name',
            },
            {
              label: 'Type',
              fieldName: 'type',
              type: 'DEAL_EXIT_TYPE',
            },
            {
              label: 'MOI',
              fieldName: 'multipleOnInvestment',
              type: 'NUMBER',
            },
            {
              label: '# Distributions',
              fieldName: 'statistics.numberOfDistributions',
              type: 'NUMBER',
            },
            {
              label: '# Old Platform Distributions',
              fieldName: 'statistics.numberOfOldPlatformDistributions',
              type: 'NUMBER',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Exited At',
              fieldName: 'exitedAt',
              type: 'DATE',
            },
            {
              label: 'Percentage Of Shares',
              fieldName: 'percentageOfShares',
              type: 'PERCENTAGE',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Deal Exits" subTitle="View and manage all the deal exits" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default DealExitsPage;
