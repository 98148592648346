import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  InvestmentEntityDematStatusEnumType,
  InvestmentEntityDematSubmissionDateEnumType,
} from '__generated__/graphql';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import Select from 'primitives/Select';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import debounce from 'utils/debounce';
import useQueryParams from 'utils/hooks/use-query-params';
import useScroll from 'utils/hooks/use-scroll';

import SendDematReminderEmailDialogButton from './SendDematReminderEmailDialogButton';

const DEMAT_INFORMATION_QUERY = gql(`
  query fetchDematInformation($cursor: ID, $limit: Int, $filters: InvestmentEntitiesFilterType) {
    dematAccounts(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        user {
          id
          name
        }
        status
        type
        dematAccount {
          id
          status
        }
      }
      pageInfo {
        hasNextPage
        cursor
        totalCount
      }
    }
  }
`);

const DOWNLOAD_DEMAT_INFORMATION_CSV_MUTATION = gql(`
  mutation Mutation($timePeriod: InvestmentEntityDematSubmissionDateEnumType) {
    downloadDematInformation(timePeriod: $timePeriod)
  }
`);

const DematInformationPage: FC = () => {
  const navigate = useNavigate();
  const { onScroll, onComponentUnmount, onDataLoad, onReset } = useScroll({
    pageIdentifier: 'dematInformationPageScrollPosition',
  });
  const { dematStatus, dematSubmissionDate, page, onLoadMore } = useQueryParams({
    dematStatus: { param: 'dematStatus', enumType: InvestmentEntityDematStatusEnumType },
    dematSubmissionDate: {
      param: 'dematSubmissionDate',
      enumType: InvestmentEntityDematSubmissionDateEnumType,
    },
  });
  const [selected, setSelected] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [csvDownloadTimePeriod, setCsvDownloadTimePeriod] = useState<string>('LAST_30_DAYS');
  const [searchParams] = useSearchParams();
  const dematStatusFilter = searchParams.get('dematStatus') ?? undefined;
  const textFilter = searchParams.get('text') ?? undefined;

  const { loading, error, data, refetch, fetchMore, networkStatus, variables } = useQuery(
    DEMAT_INFORMATION_QUERY,
    {
      variables: {
        limit: 50 * page,
        filters: {
          dematStatus: dematStatus as InvestmentEntityDematStatusEnumType | undefined,
          dematSubmissionDate: dematSubmissionDate as
            | InvestmentEntityDematSubmissionDateEnumType
            | undefined,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [downloadDematInformationCsv, { loading: downloadLoading }] = useMutation(
    DOWNLOAD_DEMAT_INFORMATION_CSV_MUTATION
  );

  const handleOnSelect = (selectedIds: Array<string>) => {
    setSelectAll(false);
    setSelected(selectedIds);
  };

  useEffect(() => {
    if (data?.dematAccounts.nodes.length) onDataLoad();
  }, [data, onDataLoad]);

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investmentEntities = data.dematAccounts.nodes;
    const pageInfo = data.dematAccounts.pageInfo;

    let dematStatusOptions = [
      {
        label: 'All',
        value: undefined,
      },
      {
        label: 'Pending',
        value: InvestmentEntityDematStatusEnumType.Pending,
      },
      {
        label: 'CML Document Pending',
        value: InvestmentEntityDematStatusEnumType.CmlDocumentPending,
      },
      {
        label: 'Needs Attention',
        value: InvestmentEntityDematStatusEnumType.NeedsAttention,
      },
      {
        label: 'Submitted',
        value: InvestmentEntityDematStatusEnumType.Submitted,
      },
    ];

    dematStatusOptions = dematStatusOptions.filter(option => {
      if (option.label !== 'Pending') return true;
      return variables?.filters?.dematSubmissionDate === undefined;
    });

    const filters = [
      {
        type: 'dematStatus',
        label: 'Status',
        options: dematStatusOptions,
      },
      {
        type: 'dematSubmissionDate',
        label: 'Submission Date',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          {
            label: 'Today',
            value: InvestmentEntityDematSubmissionDateEnumType.LastOneDay,
          },
          {
            label: 'Last Week',
            value: InvestmentEntityDematSubmissionDateEnumType.Last_7Days,
          },
          {
            label: 'Last Month',
            value: InvestmentEntityDematSubmissionDateEnumType.Last_30Days,
          },
          {
            label: 'Last 6 Months',
            value: InvestmentEntityDematSubmissionDateEnumType.Last_180Days,
          },
          {
            label: 'Last Year',
            value: InvestmentEntityDematSubmissionDateEnumType.Last_365Days,
          },
          {
            label: 'Last 2 Years',
            value: InvestmentEntityDematSubmissionDateEnumType.Last_720Days,
          },
        ],
      },
    ];

    return (
      <>
        <FilterPanel
          onFilterChange={onReset}
          activeFilters={variables?.filters}
          filters={filters}
          refetch={refetch}
        />
        <div className="p-4 w-full border-[0.5px] rounded-lg mt-4 flex flex-row justify-between">
          <div>
            <h3 className="underline mb-4">Email Reminders</h3>
            <span className="text-base font-normal text-gray-600">
              {selectAll ? pageInfo.totalCount : selected.length} of {pageInfo.totalCount}{' '}
              {dematStatusFilter !== 'undefined' && dematStatusFilter !== undefined ? (
                <span className="font-bold">
                  {dematStatusOptions.find(option => option.value === dematStatusFilter)?.label}
                </span>
              ) : (
                ''
              )}{' '}
              investment entities selected
            </span>
            {selected.length === 50 * page &&
            (dematStatusFilter !== undefined || dematStatusFilter !== 'undefined') &&
            (textFilter === undefined || textFilter === 'undefined') &&
            !selectAll ? (
              <span
                className="ml-2 underline cursor-pointer text-indigo-600"
                onClick={() => setSelectAll(true)}
              >
                Select all?
              </span>
            ) : null}
          </div>
          <SendDematReminderEmailDialogButton
            totalCount={selectAll ? pageInfo.totalCount : selected.length}
            reminderType={
              dematStatusOptions.find(option => option.value === dematStatusFilter)?.label
            }
            selectAll={selectAll}
            selectedInvestmentEntityIds={selected}
            className="h-min self-end"
          />
        </div>

        <div className="p-4 w-full border-[0.5px] rounded-lg mt-4 flex flex-row justify-between">
          <div>
            <h3 className="underline mb-4">Download CSV</h3>
            <div className="w-48">
              <Select
                options={[
                  { value: 'LAST_ONE_DAY', label: 'Today' },
                  { value: 'LAST_7_DAYS', label: 'Last Week' },
                  { value: 'LAST_30_DAYS', label: 'Last Month' },
                  { value: 'LAST_180_DAYS', label: 'Last 6 Months' },
                  { value: 'LAST_365_DAYS', label: 'Last Year' },
                  { value: 'LAST_720_DAYS', label: 'Last 2 Years' },
                ]}
                value="LAST_30_DAYS"
                fullWidth
                onChange={e => setCsvDownloadTimePeriod(e)}
              />
            </div>
          </div>
          <Button
            onClick={async () => {
              await downloadDematInformationCsv({
                variables: {
                  timePeriod: csvDownloadTimePeriod as InvestmentEntityDematSubmissionDateEnumType,
                },
              }).then(({ data }) => {
                const url = data?.downloadDematInformation;
                if (!url) return;
                window.open(url, '_blank');
              });
            }}
            className="h-min self-end"
            loading={downloadLoading}
          >
            Download CSV
          </Button>
        </div>

        <div className="flex justify-between items-end mt-8">
          <TextField
            placeholder="Search for a user"
            onChange={debounce(e => {
              refetch({
                filters: {
                  ...variables?.filters,
                  text: e.target.value,
                },
              });
            }, 500)}
          />
          <span className="text-sm text-gray-500">
            {investmentEntities.length} of {pageInfo.totalCount} members
          </span>
        </div>
        <DataTable
          data={investmentEntities}
          onClick={investmentEntity =>
            navigate(`/investment-entities/${investmentEntity.id}/demat`)
          }
          onSelect={handleOnSelect}
          onUnmount={onComponentUnmount}
          onScroll={onScroll}
          columns={[
            {
              label: 'Select',
              fieldName: 'id',
              type: 'SELECT',
            },
            {
              label: 'Name',
              fieldName: 'name',
            },
            {
              label: 'User',
              fieldName: 'user.name',
            },
            {
              label: 'Type',
              fieldName: 'type',
              type: 'INVESTMENT_ENTITY_TYPE',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() => {
            onLoadMore();
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            });
          }}
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Demat Information"
        subTitle="View demat information submitted by investors"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default DematInformationPage;
