import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const SEND_DISTRIBUTION_STATEMENTS_MUTATION = gql(`
  mutation sendDistributionStatements($dealExitId: ID!) {
    sendDistributionStatements(dealExitId: $dealExitId) {
      distributions {
        id
        status
      }
      fundDistributions {
        id
        status
      }
    }
  }
`);

const EmailDistributionStatementsConfirmButton: FC<{
  dealExitId: string;
}> = ({ dealExitId }) => {
  const [sendDistributionStatements, { loading, error }] = useMutation(
    SEND_DISTRIBUTION_STATEMENTS_MUTATION,
    {
      variables: {
        dealExitId,
      },
    }
  );

  return (
    <ConfirmButton
      onConfirm={sendDistributionStatements}
      loading={loading}
      error={error}
      variant="secondary"
      title="Send Distribution Statements PDFs"
      description="This will send the distribution statements to all investors. Please ensure all statements have been generated and reviewed before proceeding."
    >
      Send Distribution Statements PDFs
    </ConfirmButton>
  );
};

export default EmailDistributionStatementsConfirmButton;
