import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import Toast from 'primitives/Toast';
import useToast from 'primitives/Toast/useToast';

import { FormInput, FormPanel } from 'components/FormPanel';

const MERGE_INVESTMENT_ENTITY_MUTATION = gql(`
  mutation MergeInvestmentEntities($sourceInvestmentEntityId: String!, $targetInvestmentEntityId: String!) {
    mergeInvestmentEntities(sourceInvestmentEntityId: $sourceInvestmentEntityId, targetInvestmentEntityId: $targetInvestmentEntityId) {
      id
    }
  }
`);

const MergeInvestmentEntity: FC<{ id: string }> = ({ id }) => {
  const [mergeInvestmentEntities, { loading, error }] = useMutation(
    MERGE_INVESTMENT_ENTITY_MUTATION
  );
  const sourceInvestmentEntityId = id;
  const { showToast, handleToastOpen, handleToastClose } = useToast();
  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-xl font-semibold leading-6 text-gray-700 mb-8">
          Merge Investment Entity
        </h3>
        <FormPanel
          loading={loading}
          error={error}
          onSubmit={data => {
            mergeInvestmentEntities({
              variables: {
                sourceInvestmentEntityId,
                targetInvestmentEntityId: data.targetInvestmentEntityId,
              },
            }).then(data => {
              if (data.errors) {
                alert('Error merging investment entities');
              }
              handleToastOpen();
            });
          }}
        >
          <FormInput
            type="text"
            fieldName="targetInvestmentEntityId"
            label="Target Investment Entity Id"
            defaultValue={null}
          />
        </FormPanel>
        <Toast
          title="Investment Entity Merge Successful"
          message={'Investment entities have been successfully merged.'}
          show={showToast}
          handleClose={handleToastClose}
          type="success"
        />
      </div>
    </div>
  );
};

export default MergeInvestmentEntity;
