import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UnitStatementPeriodEnumType, UnitStatementYearEnumType } from '__generated__/graphql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const GENERATE_UNIT_STATEMENT_MUTATION = gql(`
  mutation GenerateUnitStatement($investmentEntityId: ID!, $period: UnitStatementPeriodEnumType!, $year: UnitStatementYearEnumType!) {
    generateUnitStatement(investmentEntityId: $investmentEntityId, period: $period, year: $year) {
      id
      type
      name
      description
      documentDate
      createdAt
      updatedAt
    }
  }
`);

const GenerateUnitStatement: FC<{
  investmentEntityId: string;
  period: UnitStatementPeriodEnumType;
  year: UnitStatementYearEnumType;
}> = ({ investmentEntityId, period, year }) => {
  const [generateUnitStatement, { loading, error }] = useMutation(
    GENERATE_UNIT_STATEMENT_MUTATION,
    {
      variables: {
        investmentEntityId,
        period,
        year,
      },
      update(cache, { data }) {
        if (!data?.generateUnitStatement) return;

        // Check if document already exists in cache
        const existingDoc = cache.identify({
          id: data.generateUnitStatement.id,
          __typename: 'InvestmentEntityDocumentType',
        });

        if (!existingDoc) {
          // If document doesn't exist, add it to the documents list
          cache.modify({
            id: `InvestmentEntityType:${investmentEntityId}`,
            fields: {
              investmentEntityDocuments(existingDocs = []) {
                const newDocRef = cache.writeFragment({
                  data: data.generateUnitStatement,
                  fragment: gql(`
                    fragment NewDocument on InvestmentEntityDocumentType {
                      id
                      type
                      name
                      description
                      documentDate
                      createdAt
                      updatedAt
                    }
                  `),
                });
                return [...existingDocs, newDocRef];
              },
            },
          });
        }
      },
    }
  );

  return (
    <ConfirmButton
      loading={loading}
      error={error}
      onConfirm={() => generateUnitStatement()}
      title={`Generate ${period} ${year} Unit Statement`}
      description="Are you sure you want to generate a unit statement?"
      confirmButtonLabel="Generate"
    >
      {`Generate ${period} ${year} Unit Statement`}
    </ConfirmButton>
  );
};

export default GenerateUnitStatement;
