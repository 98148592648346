import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DematEmailReminderTypeEnumType } from '__generated__/graphql';
import { FC, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const SEND_DEMAT_REMINDER_EMAIL = gql(`
  mutation SendDematReminderEmail($reminderType: DematEmailReminderTypeEnumType!, $investmentEntityIds: [ID], $selectAll: Boolean) {
    sendDematReminderEmail(reminderType: $reminderType, investmentEntityIds: $investmentEntityIds, selectAll: $selectAll)
    }
`);

const SendDematReminderEmailDialogButton: FC<{
  totalCount: number;
  reminderType: string | undefined;
  selectAll: boolean;
  selectedInvestmentEntityIds: Array<string>;
  className?: string;
}> = ({ totalCount, reminderType, selectAll, selectedInvestmentEntityIds, className }) => {
  const [showDialog, toggleDialog] = useState(false);

  const [sendDematReminderEmail, { loading }] = useMutation(SEND_DEMAT_REMINDER_EMAIL, {
    onCompleted() {
      toggleDialog(false);
    },
  });

  return (
    <>
      <Button
        disabled={reminderType !== 'Pending'}
        className={className}
        onClick={() => toggleDialog(true)}
      >
        Send Reminder
      </Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Send Reminder Email</DialogTitle>
          <p>
            Are you sure you want to send a <span className="font-bold">{reminderType}</span>{' '}
            reminder email to {totalCount} investor(s)?
          </p>
          <DialogActions>
            <Button variant="secondary" onClick={() => toggleDialog(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                sendDematReminderEmail({
                  variables: {
                    reminderType:
                      DematEmailReminderTypeEnumType[
                        reminderType as keyof typeof DematEmailReminderTypeEnumType
                      ],
                    investmentEntityIds: selectAll ? [] : selectedInvestmentEntityIds,
                    selectAll,
                  },
                });
              }}
              loading={loading}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default SendDematReminderEmailDialogButton;
