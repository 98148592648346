import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { PostStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

const POSTS_APPROVAL_QUERY = gql(`
  query fetchPostsForApproval(
    $cursor: ID
    $limit: Int
    $filters: PostsFilterType
    $sortBy: PostSortByEnumType
    $sortType: SortTypeEnumType
  ) {
    posts(cursor: $cursor, limit: $limit, filters: $filters, sortBy: $sortBy, sortType: $sortType) {
      nodes {
        id
        title
        status
        message
        createdAt
        updatedAt
        publishedAt
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const ApprovePostsPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, networkStatus } = useQuery(
    POSTS_APPROVAL_QUERY,
    {
      variables: {
        limit: 50,
        filters: {
          statuses: [PostStatusEnumType.UnderReview],
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const posts = data.posts.nodes;
  const pageInfo = data.posts.pageInfo;

  return (
    <>
      <DataTable
        data={posts}
        onClick={post => navigate(`/posts/${post.id}`)}
        columns={[
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Title',
            fieldName: 'title',
          },
          {
            label: 'Message',

            fieldName: 'message',
            type: 'HTML',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default ApprovePostsPage;
