import { Transition } from '@headlessui/react';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';

function FilterMenu({
  title,
  options,
  currentValue,
  onSelect,
}: {
  title?: string;
  options: { label: string; value?: string | boolean }[];
  currentValue: string | boolean | undefined | null;
  onSelect: (value?: string | boolean) => void;
}) {
  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 px-2 py-1 rounded-lg text-sm font-medium leading-6 text-gray-700 hover:bg-gray-100">
        {title}
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 mt-3 flex w-screen max-w-max">
          <div className="w-fit max-w-xl flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4 grid grid-cols-3 gap-2">
              {options.map(option => (
                <Popover.Button
                  key={`${title}-${option.label}`}
                  className={classNames(
                    'inline-flex items-center rounded-md bg-gray-100 p-3 text-sm font-medium text-gray-600 hover:cursor-pointer hover:bg-gray-200',
                    {
                      'bg-indigo-100 text-indigo-700 hover:bg-indigo-100 ring-2 ring-inset ring-indigo-500':
                        option.value === currentValue,
                    }
                  )}
                  onClick={() => onSelect(option.value)}
                >
                  {option.label}
                </Popover.Button>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default function FilterPanel({
  filters,
  activeFilters,
  refetch,
  onFilterChange,
}: {
  filters: {
    type: string;
    label: string;
    options: { label: string; value?: string | boolean | any }[];
    readable?: (value: any) => { label: string; color: string };
  }[];
  activeFilters?: any;
  refetch: (variables: any) => void;
  onFilterChange?: () => void;
}) {
  const [_, setSearchParams] = useSearchParams();

  return (
    <div className="border border-gray-200 rounded-xl">
      <div className="flex flex-row items-center gap-x-2 p-3">
        <FunnelIcon
          className="h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        {filters.map(filter => (
          <FilterMenu
            key={filter.type}
            title={filter.label}
            options={filter.options}
            currentValue={activeFilters?.[filter.type]}
            onSelect={value => {
              let queryParams: any = [];
              Object.entries({ ...activeFilters, [filter.type]: value }).forEach(([key, value]) => {
                queryParams.push([key, value]);
              });
              setSearchParams(queryParams);
              onFilterChange && onFilterChange();
              refetch({
                filters: {
                  ...activeFilters,
                  [filter.type]: value,
                },
              });
            }}
          />
        ))}
      </div>
      {activeFilters && Object.values(activeFilters).some(v => v) && (
        <div className="flex flex-row items-center gap-x-2 bg-gray-50 p-3 rounded-b-xl">
          {Object.entries(activeFilters || {}).map(([key, value]: [string, any]) =>
            value || typeof value === 'boolean' ? (
              <div
                key={`active-filter-${key}`}
                className="flex items-center gap-x-1 cursor-pointer px-2 py-1 rounded-md text-sm bg-indigo-50 text-indigo-700 hover:bg-indigo-100"
              >
                <span className="text-gray-500 text-xs">
                  {filters.find(f => f.type === key)?.label
                    ? filters.find(f => f.type === key)?.label
                    : key === 'text'
                    ? 'Search'
                    : 'Filter'}
                  :{' '}
                </span>
                {filters.find(f => f.type === key)?.readable?.(value).label || value}
                <XMarkIcon
                  className="h-5 w-5 flex-none text-gray-400 group-hover:text-indigo-500"
                  aria-hidden="true"
                  onClick={() => {
                    let queryParams: any = [];
                    Object.entries({ ...activeFilters, [key]: undefined }).forEach(
                      ([key, value]) => {
                        queryParams.push([key, value]);
                      }
                    );
                    setSearchParams(queryParams);
                    onFilterChange && onFilterChange();
                    refetch({
                      filters: {
                        ...activeFilters,
                        [key]: undefined,
                      },
                    });
                  }}
                />
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
}
