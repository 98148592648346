import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Alert from 'primitives/Alert';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

const EXITS_QUERY = gql(`
  query fetchExitsByDeal($id: ID!) {
    deal(id: $id) {
      id
      exits {
        id
        type
        percentageOfShares
        pricePerShare
        exitedAt
        createdAt
        totalSaleProceeds
        distributionExpenses
        multipleOnInvestment
      }
    }
  }
`);

const DealExits: FC = () => {
  const navigate = useNavigate();
  const { dealId } = useParams<{ dealId: string }>() as {
    dealId: string;
  };

  const { loading, error, data, refetch } = useQuery(EXITS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const exits = data.deal.exits;

  if (!exits.length)
    return (
      <div className="my-6">
        <Alert
          type="info"
          title="No exits found"
          message="Exits are marked on the company associated with the deal. You can visit the company page to add exits."
        />
      </div>
    );

  return (
    <DataTable
      data={exits}
      onClick={exit => navigate(`/deal-exits/${exit.id}`)}
      columns={[
        {
          label: 'Type',
          fieldName: 'type',
          type: 'DEAL_EXIT_TYPE',
        },
        {
          label: 'Percentage of Shares',
          fieldName: 'percentageOfShares',
          type: 'PERCENTAGE',
        },
        {
          label: 'Price Per Share',
          fieldName: 'pricePerShare',
          type: 'CURRENCY',
        },
        {
          label: 'Exited At',
          fieldName: 'exitedAt',
          type: 'DATETIME',
        },
        {
          label: 'Created At',
          fieldName: 'createdAt',
          type: 'DATETIME',
        },
        {
          label: 'Distribution Expenses',
          fieldName: 'distributionExpenses',
          type: 'NUMBER',
        },
        {
          label: 'MOI',
          fieldName: 'multipleOnInvestment',
          type: 'NUMBER',
        },
        {
          label: 'Total Sale Proceeds',
          fieldName: 'totalSaleProceeds',
          type: 'CURRENCY',
        },
      ]}
    />
  );
};

export default DealExits;
