import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConvertibleAssetCard from 'routes/Deal/Assets/ConvertibleAssetCard';
import ShareAssetCard from 'routes/Deal/Assets/ShareAssetCard';

import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import CreateConvertibleAssetDialogButton from './CreateConvertibleAssetDialogButton';
import CreateShareAssetDialogButton from './CreateShareAssetDialogButton';
import DealExits from './DealExits';
import RemoveConvertibleAssetButton from './RemoveConvertibleAssetButton';
import RemoveShareAssetButton from './RemoveShareAssetButton';
import UpdateConvertibleAssetDialog from './UpdateConvertibleAssetDialog';
import UpdateShareAssetDialog from './UpdateShareAssetDialog';
import ValuationStatistics from './ValuationStatistics';

const ASSETS_QUERY = gql(`
  query fetchAssetsByDeal($dealId: ID!) {
    deal(id: $dealId) {
      id
      companyInformation {
        id
        company {
          id
          shareClasses {
            id
            name
          }
          convertibleClasses {
            id
            name
          }
        }
      }
      shareAssets {
        id
        numberOfShares
        issueNumberOfShares
        status
        createdAt
        sourceConvertibleAsset {
          id
        }
        shareClass {
          id
          name
          issuePricePerShare
          latestPricePerShare
          issueDate
        }
      }
      convertibleAssets {
        id
        type
        status
        value
        maturityDate
        interestRate
        createdAt
        convertedAt
        convertibleClass {
          id
          name
          valuationOrCap
          latestValuationOrCap
          discount
          issueDate
        }
      }
    }
  }
`);

const DealAssets: FC = () => {
  const [shareAssetOnDisplay, setShareAssetOnDisplay] = useState<any | null>(null);
  const [convertibleAssetOnDisplay, setConvertibleAssetOnDisplay] = useState<any | null>(null);
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(ASSETS_QUERY, {
    variables: {
      limit: 50,
      dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const shareAssets = data.deal.shareAssets;
  const convertibleAssets = data.deal.convertibleAssets;
  const shareClasses = data.deal.companyInformation.company.shareClasses;
  const convertibleClasses = data.deal.companyInformation.company.convertibleClasses;

  return (
    <>
      {shareAssetOnDisplay && (
        <UpdateShareAssetDialog
          shareAsset={shareAssetOnDisplay}
          onClose={() => setShareAssetOnDisplay(null)}
        />
      )}
      {convertibleAssetOnDisplay && (
        <UpdateConvertibleAssetDialog
          convertibleAsset={convertibleAssetOnDisplay}
          onClose={() => setConvertibleAssetOnDisplay(null)}
          shareClasses={shareClasses}
        />
      )}
      <div>
        <ValuationStatistics dealId={dealId} />
        <div className="flex mt-5 gap-2">
          <CreateShareAssetDialogButton shareClasses={shareClasses} dealId={dealId} />
          <CreateConvertibleAssetDialogButton
            convertibleClasses={convertibleClasses}
            dealId={dealId}
          />
        </div>
        {shareAssets.length || convertibleAssets.length ? (
          <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-200 sm:rounded-xl mt-3">
            {shareAssets.map(shareAsset => (
              <div>
                <ShareAssetCard
                  key={shareAsset.id}
                  id={shareAsset.id}
                  status={shareAsset.status}
                  numberOfShares={shareAsset.numberOfShares}
                  issueNumberOfShares={shareAsset.issueNumberOfShares}
                  createdAt={shareAsset.createdAt}
                  shareClass={{
                    id: shareAsset.shareClass.id,
                    name: shareAsset.shareClass.name,
                    issuePricePerShare: shareAsset.shareClass.issuePricePerShare,
                    latestPricePerShare: shareAsset.shareClass.latestPricePerShare,
                    issueDate: shareAsset.shareClass.issueDate,
                  }}
                  onClick={() =>
                    setShareAssetOnDisplay({
                      id: shareAsset.id,
                      status: shareAsset.status,
                      numberOfShares: shareAsset.numberOfShares,
                      issueNumberOfShares: shareAsset.issueNumberOfShares,
                    })
                  }
                />
                <div className="flex justify-end pb-4 pr-4">
                  <RemoveShareAssetButton id={shareAsset.id} dealId={dealId} />
                </div>
              </div>
            ))}
            {convertibleAssets.map(convertibleAsset => (
              <div>
                <ConvertibleAssetCard
                  key={convertibleAsset.id}
                  id={convertibleAsset.id}
                  type={convertibleAsset.type}
                  status={convertibleAsset.status}
                  value={convertibleAsset.value}
                  maturityDate={convertibleAsset.maturityDate}
                  createdAt={convertibleAsset.createdAt}
                  convertedAt={convertibleAsset.convertedAt}
                  convertibleClass={{
                    id: convertibleAsset.convertibleClass.id,
                    name: convertibleAsset.convertibleClass.name,
                    valuationOrCap: convertibleAsset.convertibleClass.valuationOrCap,
                    discount: convertibleAsset.convertibleClass.discount,
                    latestValuationOrCap: convertibleAsset.convertibleClass.latestValuationOrCap,
                    issueDate: convertibleAsset.convertibleClass.issueDate,
                  }}
                  onClick={() =>
                    setConvertibleAssetOnDisplay({
                      id: convertibleAsset.id,
                      type: convertibleAsset.type,
                      status: convertibleAsset.status,
                      value: convertibleAsset.value,
                      maturityDate: convertibleAsset.maturityDate,
                      interestRate: convertibleAsset.interestRate,
                    })
                  }
                />
                <div className="flex justify-end pb-4 pr-4">
                  <RemoveConvertibleAssetButton id={convertibleAsset.id} dealId={dealId} />
                </div>
              </div>
            ))}
          </ul>
        ) : (
          <EmptyState title="No Assets" description="This deal does not have any assets yet." />
        )}
        <p className="mt-5 text-lg font-bold">Exits</p>
        <DealExits />
      </div>
    </>
  );
};

export default DealAssets;
