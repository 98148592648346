import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_DEAL_FINALISATION_DATE_MUTATION = gql(`
  mutation UpdateDealFinalisationDate($dealId: ID!, $finalisedAt: String!) {
    updateDealFinalisationDate(id: $dealId, finalisedAt: $finalisedAt) {
      id
      updatedAt
      finalisedAt
    }
  }
`);

const UpdateDealFinalisationDate: FC<{
  id: string;
  finalisedAt?: string | null;
}> = ({ id, finalisedAt }) => {
  const [updateDealFinalisationDate, { loading, error }] = useMutation(
    UPDATE_DEAL_FINALISATION_DATE_MUTATION
  );

  if (!finalisedAt) {
    return null;
  }

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateDealFinalisationDate({
          variables: {
            dealId: id,
            finalisedAt: data.finalisedAt,
          },
        });
      }}
    >
      <FormInput
        type="date"
        fieldName="finalisedAt"
        label="Finalisation Date"
        defaultValue={finalisedAt}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateDealFinalisationDate;
