import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const FINALISE_DEAL_EXIT_MUTATION = gql(`
  mutation finaliseDealExit($dealExitId: ID!) {
    finaliseDealExit(id: $dealExitId) {
      id
      status
    }
  }
`);

const FinaliseDealExitConfirmButton: FC<{
  dealExitId: string;
}> = ({ dealExitId }) => {
  const [finaliseDealExit, { loading, error }] = useMutation(FINALISE_DEAL_EXIT_MUTATION, {
    variables: {
      dealExitId,
    },
  });

  return (
    <ConfirmButton
      onConfirm={finaliseDealExit}
      loading={loading}
      error={error}
      variant="primary"
      title="Finalise Deal Exit"
      description="This will finalise the deal exit and make it visible to investors. Please ensure all distributions and statements have been reviewed before proceeding."
    >
      Finalise Deal Exit
    </ConfirmButton>
  );
};

export default FinaliseDealExitConfirmButton;
