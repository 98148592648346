import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import CreateExitDialogButton from './CreateExitDialogButton';

const EXITS_QUERY = gql(`
  query fetchDealExitsByCompany($id: ID!) {
    company(id: $id) {
      id
      exits {
        id
        type
        percentageOfShares
        pricePerShare
        status
        deal {
          id
          schemeName
        }
        exitedAt
        distributionExpenses
        multipleOnInvestment
        statistics {
          numberOfDistributions
        }
      }
    }
  }
`);

const CompanyExits: FC = () => {
  const { companyId } = useParams<{ companyId: string }>() as {
    companyId: string;
  };

  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(EXITS_QUERY, {
    variables: {
      id: companyId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const exits = data.company.exits;

  return (
    <>
      <div className="flex justify-between items-center">
        <CreateExitDialogButton companyId={companyId} />
      </div>
      <DataTable
        data={exits}
        onClick={dealExit => navigate(`/deal-exits/${dealExit.id}`)}
        columns={[
          {
            label: 'Scheme',
            fieldName: 'deal.schemeName',
          },
          {
            label: 'Type',
            fieldName: 'type',
            type: 'DEAL_EXIT_TYPE',
          },
          {
            label: 'Number of Distributions',
            fieldName: 'statistics.numberOfDistributions',
            type: 'NUMBER',
          },
          {
            label: 'Price Per Share',
            fieldName: 'pricePerShare',
            type: 'CURRENCY',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Exited At',
            fieldName: 'exitedAt',
            type: 'DATE',
          },
          {
            label: 'Distribution Expenses',
            fieldName: 'distributionExpenses',
            type: 'CURRENCY',
          },
          {
            label: 'MOI',
            fieldName: 'multipleOnInvestment',
            type: 'NUMBER',
          },
          {
            label: 'Percentage Of Shares',
            fieldName: 'percentageOfShares',
            type: 'PERCENTAGE',
          },
        ]}
      />
    </>
  );
};

export default CompanyExits;
