import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import Button from 'primitives/Button';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_FOOTNOTES_ON_UNIT_STATEMENT = gql(`
  mutation UpdateFootnotesOnUnitStatement($dealId: ID!, $footnotesOnUnitStatement: String!) {
    updateFootnotesOnUnitStatement(dealId: $dealId, footnotesOnUnitStatement: $footnotesOnUnitStatement) {
      id
      footnotesOnUnitStatement
    }
  }
`);

const REMOVE_FOOTNOTES_ON_UNIT_STATEMENT = gql(`
  mutation RemoveFootnotesOnUnitStatement($dealId: ID!) {
    removeFootnotesOnUnitStatement(dealId: $dealId) {
      id
      footnotesOnUnitStatement
    }
  }
`);

const UpdateFootnotesOnUnitStatement: FC<{
  id: string;
  footnotesOnUnitStatement?: string | null;
}> = ({ id, footnotesOnUnitStatement }) => {
  console.log(footnotesOnUnitStatement);
  const [updateFootnotesOnUnitStatement, { loading, error }] = useMutation(
    UPDATE_FOOTNOTES_ON_UNIT_STATEMENT
  );

  const [removeFootnotesOnUnitStatement, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_FOOTNOTES_ON_UNIT_STATEMENT, {
      onCompleted: () => {
        window.location.reload();
      },
    });

  return (
    <>
      <FormPanelWithReadMode
        loading={loading}
        error={error}
        onSubmit={data => {
          console.log(id, data.footnotesOnUnitStatement);
          updateFootnotesOnUnitStatement({
            variables: {
              dealId: id,
              footnotesOnUnitStatement: data.footnotesOnUnitStatement,
            },
          });
        }}
      >
        <FormInput
          type="text"
          fieldName="footnotesOnUnitStatement"
          label="Footnotes On Unit Statement"
          defaultValue={footnotesOnUnitStatement || ''}
        />
      </FormPanelWithReadMode>
      <div>
        {removeError && <ErrorMessage error={removeError} />}
        <Button
          loading={removeLoading}
          onClick={() => removeFootnotesOnUnitStatement({ variables: { dealId: id } })}
        >
          Remove Footnotes On Unit Statement
        </Button>
      </div>
    </>
  );
};

export default UpdateFootnotesOnUnitStatement;
