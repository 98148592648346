import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, ReactElement } from 'react';

import Badge from 'primitives/Badge';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { formatCurrency } from 'utils/format-helper';

interface DealExitCardProps {
  id: string;
  type: string;
  percentageOfShares: number;
  pricePerShare: number;
  exitedAt: string;
  status: string;
  multipleOnInvestment: number;
  comments?: string | null;
  footnotes?: string | null;
  deal: {
    id: string;
    schemeName?: string | null;
  };
}

const DealExitCard: FC<DealExitCardProps> = ({
  type,
  percentageOfShares,
  pricePerShare,
  exitedAt,
  status,
  multipleOnInvestment,
  comments,
  footnotes,
  deal,
}) => {
  function lineItem(
    label: string,
    value: string | ReactElement | JSX.Element,
    color: string = 'text-gray-600'
  ) {
    return (
      <div className="gap-x-2 flex">
        <span className="text-xs leading-5 text-gray-500">{label}:</span>
        <span className={classNames('text-xs font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  const { label, color } = statusEnumToReadable(status);

  return (
    <div className="rounded-lg border border-gray-200 bg-white p-2">
      <div className="flex items-start justify-between">
        <div>
          <h3 className="font-medium text-green-600">Exit from {deal.schemeName}</h3>
          <div className="mt-1 text-sm text-gray-500">
            {lineItem('Type', type)}
            {lineItem('Percentage of Shares Liquidated', `${percentageOfShares}%`)}
            {lineItem('Price per Share', formatCurrency(pricePerShare))}
            {lineItem('Multiple on Investment', `${multipleOnInvestment}x`)}
            {lineItem('Exit Date', dayjs(exitedAt).format('DD MMMM YYYY'))}
            {lineItem('Status', <Badge label={label} color={color} showIndicator={true} />)}
            {lineItem('Comments', comments || '-')}
            {lineItem('Footnotes', footnotes || '-')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealExitCard;
