import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UnitStatementPeriodEnumType, UnitStatementYearEnumType } from '__generated__/graphql';
import { FC, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

const REGENERATE_UNIT_STATEMENTS_MUTATION = gql(`
  mutation RegenerateUnitStatementsByDeal($dealId: ID!, $period: UnitStatementPeriodEnumType!, $year: UnitStatementYearEnumType!) {
    regenerateUnitStatementsByDeal(dealId: $dealId, period: $period, year: $year) {
      id
      type
      name
      description
      documentDate
      createdAt
      updatedAt
    }
  }
`);

const RegenerateUnitStatementsDialogButton: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);

  const [regenerateUnitStatements, { loading, error }] = useMutation(
    REGENERATE_UNIT_STATEMENTS_MUTATION,
    {
      onCompleted: data => {
        if (data?.regenerateUnitStatementsByDeal) {
          setDocuments(data.regenerateUnitStatementsByDeal);
        }
      },
    }
  );

  const handleSubmit = async (formData: any) => {
    await regenerateUnitStatements({
      variables: {
        dealId: formData.dealId,
        period: formData.period,
        year: formData.year,
      },
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setDocuments([]);
  };

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;
    if (error) return <ErrorMessage error={error} />;

    if (documents.length > 0) {
      return (
        <div className="mt-4">
          <h3 className="text-lg font-medium mb-2">Generated Documents</h3>
          <DataTable
            data={documents}
            columns={[
              {
                label: 'Name',
                fieldName: 'name',
              },
              {
                label: 'Type',
                fieldName: 'type',
              },
              {
                label: 'Description',
                fieldName: 'description',
              },
              {
                label: 'Document Date',
                fieldName: 'documentDate',
                type: 'DATE',
              },
              {
                label: 'Created At',
                fieldName: 'createdAt',
                type: 'DATETIME',
              },
              {
                label: 'Updated At',
                fieldName: 'updatedAt',
                type: 'DATETIME',
              },
            ]}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Regenerate Unit Statements</Button>

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogPanel className="sm:max-w-2xl">
          <DialogTitle>Regenerate Unit Statements</DialogTitle>

          <div className="mt-4">
            <p className="text-gray-600">
              This will regenerate unit statements for all investors in this deal for the selected
              period.
            </p>

            {documents.length === 0 && (
              <FormPanel
                onSubmit={handleSubmit}
                loading={loading}
                error={error}
                onCancel={handleClose}
                submitButtonLabel="Regenerate"
              >
                <FormInput type="text" label="Deal ID" fieldName="dealId" defaultValue={''} />
                <FormInput
                  type="select"
                  label="Period"
                  fieldName="period"
                  defaultValue={UnitStatementPeriodEnumType.H2}
                  options={[
                    {
                      label: 'H2',
                      value: UnitStatementPeriodEnumType.H2,
                    },
                  ]}
                />
                <FormInput
                  type="select"
                  label="Year"
                  fieldName="year"
                  defaultValue={UnitStatementYearEnumType.Year_2024}
                  options={[
                    {
                      label: '2024',
                      value: UnitStatementYearEnumType.Year_2024,
                    },
                  ]}
                />
              </FormPanel>
            )}

            {renderContent()}
          </div>

          {documents.length > 0 && (
            <DialogActions>
              <Button variant="primary" onClick={handleClose}>
                Done
              </Button>
            </DialogActions>
          )}
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default RegenerateUnitStatementsDialogButton;
