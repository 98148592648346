import { FC, ReactNode } from 'react';

const Td: FC<{ children: ReactNode }> = ({ children }) => (
  <td className="p-2 border border-gray-200">{children}</td>
);

const Th: FC<{ children: ReactNode }> = ({ children }) => (
  <th className="bg-gray-50 p-2 text-left border border-gray-200 text-sm font-semibold">
    {children}
  </th>
);

const Table: FC<{ children: ReactNode }> = ({ children }) => (
  <table className="w-full border-collapse my-4 rounded-lg overflow-hidden">{children}</table>
);

const TableContainer: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="relative overflow-x-auto pr-4 pb-4">{children}</div>
);

interface OldDistribution {
  id: string;
  investmentEntity: {
    id: string;
    name: string;
  };
  proceedsFromExit: number;
  distributedAmount: number;
  isPartial: boolean;
}

interface OldPlatformDistributionsTableProps {
  distributions: (OldDistribution | null)[];
  type: 'regular' | 'fund';
}

const OldPlatformDistributionsTable: FC<OldPlatformDistributionsTableProps> = ({
  distributions,
  type,
}) => {
  const validDistributions = distributions.filter((d): d is OldDistribution => d !== null);

  return (
    <div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <Th>#</Th>
              <Th>Investment Entity</Th>
              <Th>Proceeds from Exit</Th>
              <Th>Distributed Amount</Th>
              <Th>Partial</Th>
            </tr>
          </thead>
          <tbody>
            {validDistributions.map((distribution, index) => (
              <tr key={distribution.id}>
                <Td>{index + 1}</Td>
                <Td>{distribution.investmentEntity.name}</Td>
                <Td>
                  {new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR',
                  }).format(distribution.proceedsFromExit)}
                </Td>
                <Td>
                  {new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR',
                  }).format(distribution.distributedAmount)}
                </Td>
                <Td>{distribution.isPartial ? 'Yes' : 'No'}</Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OldPlatformDistributionsTable;
