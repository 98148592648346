import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealExitTypeEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import dealExitTypeToReadable from 'utils/enums/deal-exit-type-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const DEAL_DATA_FOR_EXIT_QUERY = gql(`
  query fetchDealDataForExit($companyId: ID!) {
    company(id: $companyId) {
      deals {
        id
        schemeName
        status
        finalisedAt
      }
    }
  }
`);

const CREATE_DEAL_EXIT_MUTATION = gql(`
  mutation CreateDealExit(
    $dealId: ID!
    $exitPricePerShare: Float!
    $exitedAt: String!
    $percentageOfSharesToExit: Float!
    $type: DealExitTypeEnumType!
    $comments: String
    $distributionExpenses: Float
    $multipleOnInvestment: Float!
    $totalSaleProceeds: Float
    $footnotes: String
  ) {
    createDealExit(
      dealId: $dealId
      exitPricePerShare: $exitPricePerShare
      exitedAt: $exitedAt
      percentageOfSharesToExit: $percentageOfSharesToExit
      type: $type
      comments: $comments
      distributionExpenses: $distributionExpenses
      multipleOnInvestment: $multipleOnInvestment
      totalSaleProceeds: $totalSaleProceeds
      footnotes: $footnotes
    ) 
    {
      id
      type
      createdAt
      updatedAt
      totalSaleProceeds
      footnotes
      distributionExpenses
      multipleOnInvestment
      pricePerShare
      exitedAt
      percentageOfShares
      comments
    }
  }
`);

const CreateDealExitDialogButton: FC<{ companyId: string }> = ({ companyId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { loading, error, data, refetch } = useQuery(DEAL_DATA_FOR_EXIT_QUERY, {
    variables: {
      companyId,
    },
  });

  const [createDealExit, { loading: exitLoading, error: exitError }] = useMutation(
    CREATE_DEAL_EXIT_MUTATION,
    {
      refetchQueries: ['fetchDealExitsByCompany'],
    }
  );

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    return (
      <DialogPanel className="min-w-[60vw] min-h-[60vh]">
        <DialogTitle>Initiate Exit</DialogTitle>
        <FormPanel
          loading={exitLoading}
          error={exitError}
          onCancel={() => toggleDialog(false)}
          onSubmit={data => {
            createDealExit({
              variables: {
                dealId: data.dealId,
                exitPricePerShare: data.exitPricePerShare,
                exitedAt: data.exitedAt,
                percentageOfSharesToExit: data.percentageOfSharesToExit,
                type: data.type,
                comments: data.comments,
                distributionExpenses: data.distributionExpenses
                  ? parseFloat(data.distributionExpenses)
                  : null,
                multipleOnInvestment: data.multipleOnInvestment,
                totalSaleProceeds: data.totalSaleProceeds
                  ? parseFloat(data.totalSaleProceeds)
                  : null,
              },
            }).then(() => toggleDialog(false));
          }}
          buttonRef={buttonRef}
        >
          <FormInput
            type="select"
            fieldName="dealId"
            label="Deal"
            defaultValue={''}
            options={data.company.deals.map(deal => ({
              label: `${deal.schemeName || ''} ${
                deal.finalisedAt
                  ? `(${statusEnumToReadable(deal.status).label}, Finalised: ${dayjs(
                      deal.finalisedAt
                    ).format('DD/MM/YYYY')}) `
                  : `(${statusEnumToReadable(deal.status).label}) `
              }`,
              value: deal.id,
            }))}
            validators={{ required: true }}
          />
          <FormInput
            type="select"
            fieldName="type"
            label="Type"
            defaultValue={DealExitTypeEnumType.Acquisition}
            options={Object.values(DealExitTypeEnumType).map(type => ({
              label: dealExitTypeToReadable(type).label,
              value: type,
            }))}
            validators={{ required: true }}
          />
          <FormInput
            type="currency"
            fieldName="exitPricePerShare"
            label="Liquidation Price Per Share"
            defaultValue=""
            validators={{ required: true }}
          />
          <FormInput
            type="date"
            fieldName="exitedAt"
            label="Liquidation Date"
            defaultValue=""
            validators={{ required: true }}
          />
          <FormInput
            type="number"
            fieldName="percentageOfSharesToExit"
            label="Percentage of Shares to Liquidate"
            defaultValue={100}
            validators={{ required: true }}
          />
          <FormInput type="textarea" fieldName="comments" label="Comments" defaultValue="" />
          <FormInput
            type="number"
            fieldName="distributionExpenses"
            label="Distribution Expenses"
            defaultValue=""
          />
          <FormInput
            type="number"
            fieldName="multipleOnInvestment"
            label="Multiple on Investment"
            defaultValue=""
            validators={{ required: true }}
          />
          <FormInput
            type="currency"
            fieldName="totalSaleProceeds"
            label="Total Sale Proceeds"
            helperText="Total amount received from the sale"
            defaultValue=""
          />
          <FormInput type="textarea" fieldName="footnotes" label="Footnotes" defaultValue="" />
        </FormPanel>
        <DialogActions>
          <Button
            variant="secondary"
            loading={exitLoading}
            onClick={() => {
              // @ts-ignore
              buttonRef.current ? buttonRef.current.cancel() : toggleDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={exitLoading}
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.submit();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </DialogPanel>
    );
  }

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Initiate Exit</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        {renderContent()}
      </Dialog>
    </>
  );
};

export default CreateDealExitDialogButton;
